<template>
  <div class="vuse-content-wrapper position-skill">
    <smart-breadcrumbs :items="navigationElements" />

    <v-card>
      <v-row align="center" class="mx-0 pa-3 text-center flex-column">
        <h2 class="mx-5">Asignación de Competencias</h2>
        <span class="text-center">Cargo: {{ position && position.name }}</span>
      </v-row>

      <v-row no-gutters justify="end" class="px-3">
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on, attrs }"> -->
        <!-- v-bind="attrs"
              v-on="on" -->
        <v-btn
          small
          color="primary"
          dark
          style="float: right"
          @click.prevent="handleCreate()"
          >Asignar
        </v-btn>
        <!-- </template>
          <span>Crear Estrato</span>
        </v-tooltip> -->
      </v-row>
      <v-row class="mx-0">
        <label class="col-12 py-0">Filtrar por:</label>
        <v-col cols="12" lg="3" md="4" sm="6">
          <v-text-field
            dense
            solo
            hide-details="auto"
            label="Búsqueda"
            v-model="filters.search"
          />
        </v-col>
        <v-col cols="12" sm class="d-flex align-end justify-end">
          <v-btn
            small
            color="primary"
            max-width="35"
            min-width="auto"
            dark
            class="px-1 py-1"
            v-if="filterActivated"
            @click.prevent="
              filterActivated = false
              cleanFilters()
              getPositionSkills()
            "
          >
            <v-icon dark> mdi-delete </v-icon>
          </v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                max-width="35"
                min-width="auto"
                dark
                small
                class="px-1 py-1 ml-1"
                @click.prevent="
                  filterActivated = true
                  getPositionSkills()
                "
                ><v-icon dark> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>Filtrar</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :itemKey="'id'"
        :hideFooter="true"
        :hideHeader="false"
        :multiSort="false"
        :loading="loading"
        :class="'my-2 pb-2'"
        :sortable="false"
        :page="page"
        :pages="pages"
        :extraButtoms="false"
        :showCreate="false"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </v-card>
    <modal name="crudModal" :height="'80%'" :width="dialogWidth" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center mb-4">
          {{ flow === 'create' ? 'Asignar Competencia' : 'Editar Asignación' }}
        </h2>

        <span v-if="flow === 'edit' && item.created_at"
          >Fecha de la asignación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
          {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
        >
        <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row mx-0">
          <v-row class="mx-0 mt-4">
            <v-col cols="12" md="6" lg="6" class="pt-0">
              <v-row no-gutters>
                <label class="caption">Competencia</label>
                <multiselect
                  track-by="id"
                  label="name"
                  placeholder="Seleccionar"
                  v-model="selectedSkill"
                  :options="skills"
                  :multiple="false"
                  :loading="loadingSkills"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                  @input="resetValueField()"
                  :disabled="flow === 'edit'"
                />
              </v-row>
            </v-col>

            <v-col
              cols="12"
              lg="3"
              sm="6"
              class=""
              v-if="selectedSkill && selectedSkill.field_type !== 'boolean'"
            >
              <v-text-field
                :key="valueIndex"
                label="Valor"
                placeholder="Valor asignado"
                v-model="value"
                :rules="[rules.required]"
                :step="
                  selectedSkill && selectedSkill.field_type === 'integer' ? '1' : '0.01'
                "
                type="number"
                hide-details="auto"
                min="0"
                :disabled="!selectedSkill"
              />
            </v-col>

            <!-- <v-col cols="12" sm="6" lg="3" class="">
              <v-text-field
                type="date"
                label="Fecha de actualización"
                v-model="date"
                :rules="[rules.required]"
                :disabled="!selectedSkill"
              />
            </v-col> -->
          </v-row>
        </v-form>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            :disabled="!valid"
            color="primary"
            v-if="flow === 'create'"
            @click="createItem()"
          >
            Asignar
          </v-btn>
          <v-btn
            v-else
            small
            style="margin: auto; margin-top: 10px"
            :disabled="!valid"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>
          <v-btn
            small
            color="primary"
            style="margin: auto; margin-top: 10px"
            primary
            @click="reset"
          >
            limpiar
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('crudModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>
<script>
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import { toLocaleDateString } from '@/utils/helpers'
  export default {
    name: 'PositionSkills',
    components: {
      SimpleList,
    },
    props: {},
    data() {
      return {
        config: {},
        loading: true,
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          { text: 'Tipo', value: 'typeText' },
          { text: 'Nombre de la competencia', value: 'skill.name' },
          { text: 'Condición', value: 'valueText' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '100px' },
        ],
        items: [],

        position: {},
        page: 1,
        pages: 1,
        activeInterview: false,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        typeOptions: [
          { id: 0, value: 'Educación' },
          { id: 1, value: 'Experiencia' },
          { id: 2, value: 'Habilidades' },
        ],
        filters: {
          search: '',
        },
        value: null,
        valueIndex: 100,
        skills: [],
        selectedSkill: null,
        loadingSkills: true,
        typeInterview: [
          { id: 1, name: 'Presencial' },
          { id: 0, name: 'Remoto' },
        ],
        itemsPeriodicity: [
          { id: 0, name: 'Diario' },
          { id: 1, name: 'Semanal' },
          { id: 2, name: 'Quincenal' },
          { id: 3, name: 'Mensual' },
          { id: 4, name: 'Trimestral' },
          { id: 5, name: 'Semestral' },
          { id: 6, name: 'Anual' },
        ],
        name: '',
        loadingText: 'Cargando registros',
        valid: true,
        userData: {
          name: '',
          lastname: '',
          email: '',
          phone: '',
          age: null,
        },
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          url: (v) => (v ? this.isURL(v) : true) || 'La URL es inválida',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
        navigationElements: [
          {
            text: 'Talento Humano',
            disabled: false,
            query: null,
            params: null,
            name: 'administrative/human-talent/AdminPanel',
            href: '/administrative/human-talent/admin-panel',
          },
          {
            text: 'Perfiles de Cargos',
            disabled: false,
            query: null,
            params: null,
            name: 'administrative/human-talent/Positions',
            href: '/administrative/human-talent/positions',
          },
          {
            text: 'Asignación de Competencias',
            disabled: true,
          },
        ],
      }
    },
    watch: {
      company() {
        this.getPositionSkills()
      },
    },
    created() {
      this.getPosition()
      this.getSkills()
    },
    computed: {
      dialogWidth() {
        return this.$vuetify.breakpoint.smAndDown ? '95%' : '60%'
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,

      cleanFilters() {
        this.filters = {
          search: '',
        }
      },
      resetValueField() {
        this.value = null
        this.valueIndex++
      },
      getPosition() {
        this.$axios.get('positions/' + this.$route.params.positionId).then((response) => {
          this.position = response.data

          this.getPositionSkills()
        })
      },
      getSkills(search) {
        this.loadingSkills = true
        let searchStr = ''
        if (search) {
          searchStr = '&search=' + search
        }
        this.$axios.get('skills?limit=100' + searchStr).then((response) => {
          this.skills = response.data
          this.loadingSkills = false
        })
      },

      async getPositionSkills() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        let searchString = ''
        const withString = '&with[]=skill'
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
        }
        queryParams = searchString + withString

        this.$axios
          .get(
            'position-skills?position_id=' +
              this.position.id +
              '&page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.map((x) => {
              x.typeText = this.typeOptions.find((type) => type.id === x.skill.type).value
              x.valueText = x.skill.qualitative ? 'Requerido' : parseFloat(x.value)
              return x
            })
            this.loading = false
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.selectedSkill = null
        this.value = null

        this.$modal.show('crudModal')
      },

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getPositionSkills()
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.selectedSkill = item.skill
        switch (item.skill.field_type) {
          case 'integer':
            this.value = parseInt(item.value)
            break
          case 'number':
            this.value = item.value
            break
          case 'boolean':
            this.value = 1
            break

          default:
            break
        }
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.selectedSkill = null
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      async createItem() {
        const data = {}
        data.position_id = this.$route.params.positionId
        data.skill_id = this.selectedSkill.id
        data.value = this.selectedSkill.field_type !== 'boolean' ? this.value : 1
        this.$axios.post('position-skills', data).then(() => {
          this.page = 1
          this.reset()
          this.getPositionSkills()
          this.$modal.hide('crudModal')
        })
      },

      async saveItem() {
        const data = {}
        data.position_id = this.$route.params.positionId
        data.skill_id = this.selectedSkill.id
        data.value = this.selectedSkill.field_type !== 'boolean' ? this.value : 1
        this.$axios.patch('position-skills/' + this.item.id, data).then(() => {
          this.page = 1
          this.reset()
          this.getPositionSkills()
          this.$modal.hide('crudModal')
        })
      },

      async deleteItem(item) {
        this.$axios.delete('position-skills/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getPositionSkills()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getPositionSkills()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
  // .position-skill {

  // }
</style>
